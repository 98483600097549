.pincode-input-container {
}
.pincode-input-text {
}
.pincode-input-text:focus {
}
@media (max-width: 600px) {
  .pincode-input-text {
    width: 53px !important;
    height: 60px !important;
  }
}
