a:link {
  color: #5c14ce !important;
  /* text-decoration: none; */
  cursor: pointer;
}

.signup-phone-input input {
  border-bottom: 1.5px solid #cbd5e0;
  border-right: 0px solid #fff;
  border-left: 0px solid #fff;
  border-top: 0px solid #fff;
  padding: 0.5em 0em;
  padding-left: 0.4em;
  outline: 0px !important;
  -webkit-appearance: none;
}

.signup-phone-input input:focus {
  border-bottom: 2px solid #5c14ce;
  outline: 0px !important;
  -webkit-appearance: none;
}

.signup-phone-input input:after {
  content: "*";
  color: red;
}

/* .currencyInput {
  border-color: #c6cfdb !important;
} */

.currencyInput:focus {
  border-color: #5c14ce !important;
  outline: 0px !important;
}

.accountSettingsPhone:disabled {
  opacity: 1;
}

.accountSettingsPhone input {
  border-radius: 6px;
  border: 0.6px solid rgba(50, 50, 93, 0.3);
}

.accountSettingsPhone input:focus {
  border: 0.6px solid #5c14ce;
  /* outline: #5c14ce; */
  box-shadow: none !important;
  outline: none !important;
}

.accountSettingsPhone2:disabled {
  opacity: 1;
}

.accountSettingsPhone2 input {
  border-radius: 6px;
  border: 0.6px solid rgba(255, 255, 255, 0.3);
}
.accountSettingsPhone2 input {
  border-radius: 6px;
  border: 0.6px solid rgba(255, 255, 255, 0.3);
}

.accountSettingsPhone2 input:focus {
  border: 0.6px solid #fff;
  /* outline: #5c14ce; */
  box-shadow: none !important;
  outline: none !important;
}

#organizationSelect {
  padding: 12px 20px 15px 20px;
}

#reportSelect {
  padding: 0px 20px 0px 20px;
}

#reportSelect:first-child {
  margin-right: 10px !important;
}

.rdw-link-modal-btn {
  background-color: #5c14ce !important;
  color: #cbd5e0;
}

#hello2 {
  background-color: #5c14ce;
}

.demo-editor {
  padding-left: 1em;
  padding-right: 1em;
}

#chatInput {
  outline: 0px solid !important;
}

.delete-hover:hover {
  color: #da2028;
}
